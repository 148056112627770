import { computed, observable } from 'mobx';
import { capitalize } from 'lodash';

import Model from 'core/model/Model';
import UserCollection from 'app/stores/user/UserCollection';
import AddOnCollection from 'app/stores/companySettings/addOn/AddOnCollection';
import DeviceCollection from 'app/stores/device/DeviceCollection';
import PlanCollection from 'app/stores/sudo/PlanCollection';
import CompanyNoteCollection from 'app/stores/sudo/CompanyNoteCollection';

export default class Company extends Model {
  @observable
  fetchingDetails = false;

  get defaults() {
    return {
      maxFps24h: -1
    };
  }

  get urlRoot() {
    if (this.collection?.isAdmin) {
      return '/api/ui/sudo/companies';
    }
    return undefined;
  }

  get omitDuringSerialize() {
    return ['notes', 'plans', 'devices', 'users', 'addOns'];
  }

  deserialize(company) {
    company = super.deserialize(company);
    if (company) {
      const { devices, plans, users, permissions, addOns, notes } = company;

      company.notes = new CompanyNoteCollection(notes);
      company.plans = new PlanCollection({ plans, companyId: company.id });

      company.plans.each((plan) => {
        if (plan.get('planHistory')?.length) {
          plan.set(
            'planHistory',
            new PlanCollection({
              plans: plan
                .get('planHistory')
                .map((planHistory) => ({ ...planHistory.plan, ...planHistory, isHistory: true }))
            })
          );
        }
      });
      (devices || []).forEach((device) => {
        device.plan = company.plans.get(device.plan_id);
      });
      company.devices = new DeviceCollection(devices);

      company.users = new UserCollection(users, { admin: true, companyId: company.id });
      if (permissions) {
        company.permissions = permissions.permissions;
      }
      if (addOns) {
        company.addOns = new AddOnCollection(addOns);
      }

      company.platform = company.company_kvs?.platform || 'classic';
    }
    return company;
  }

  @computed
  get companyId() {
    return Number(this.id);
  }

  @computed
  get numActivePlans() {
    return (
      (!this.fetchingDetails &&
        this.get('plans') &&
        this.get('plans').models.filter((plan) => plan.get('active')).length) ||
      0
    );
  }

  @computed
  get numDevices() {
    const { devices } = this.get();
    return (devices && devices.length) || 0;
  }

  @computed
  get numUsers() {
    const { users } = this.get();
    return (users && users.length) || 0;
  }

  @computed
  get trialEndDate() {
    const { trial_end_date, company_plan_id } = this.get();
    return trial_end_date && company_plan_id === 'TRIAL' ? trial_end_date : null;
  }

  @computed
  get companyPlan() {
    return capitalize(this.get('company_plan_id'));
  }

  @computed
  get isKentik() {
    return this.get('company_name').includes('kentik');
  }

  @computed
  get isActiveTrial() {
    return this.get('company_plan_id') === 'TRIAL' && this.get('company_status') !== 'EXP';
  }

  @computed
  get isExpiredTrial() {
    return this.get('company_plan_id') === 'TRIAL' && this.get('company_status') === 'EXP';
  }

  @computed
  get isSuspendedCompany() {
    return this.get('company_status') === 'SUSP';
  }

  @computed
  get isActiveCustomer() {
    return this.get('company_plan_id') === 'CUSTOMER';
  }

  @computed
  get isFreePlan() {
    return this.get('company_plan_id') === 'FREE';
  }

  @computed
  get lastLogin() {
    const { users } = this.get();
    return users && users.map((user) => user.last_login).sort()[0];
  }

  /**
   * Just returns the first user in a company that is an Admin or Super Admin. We allow you to specify the user to spoof as,
   * but as a default we just select an Admin for now. At least one admin or super admin must exist in a company to be able
   * to spoof to this company!!!
   */
  @computed
  get spoofAsUser() {
    const { users } = this.get();
    return users && users.models.find((user) => user.get('user_level') >= 1);
  }

  get messages() {
    return {
      create: `Company ${this.get('company_name_full')} was added successfully`,
      update: `Company ${this.get('company_name_full')} was updated successfully`,
      destroy: `Company ${this.get('company_name_full')} was removed successfully`
    };
  }
}
